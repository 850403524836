@mixin listView {
    border: 1px solid #b7b7b7;
    border-radius: 8px;
    padding-left: 0;
    list-style-type: none;
    list-style: none;
    overflow-y: scroll;
}

@mixin listViewData {
    border-bottom: 1px solid #b7b7b7;
}

@mixin circle($width:25px, $height:$width) {
    width: $width;
    height: $height;
    border-radius: 50%;
}

@mixin Button_CustomInit {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
}

@mixin Button_Submit($width:60px, $height:24px) {
    width: $width;
    height: $height;
    margin-left: 16px;
    border-radius: 5px;
    background-color: red;
}

@mixin BaseInputGroup {
    position: relative;
    font-size: 14px;
    padding-top: 1.2rem;
    .top_label{
        position: absolute;
        top: 0;
        font-size: var(--font-size-small);
        opacity: 1;
        transform: translateY(0);
        transition: all 0.2s ease-out;
    }
    input{
        height: 1.5rem;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
        text-indent: 8px;
        outline-style: none;
        &:focus{
            box-shadow: 0 0 3px #2261FF;
        }
    } 
    select{
        height: 1.5rem;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
        text-indent: 4px;
        outline-style: none;
        &:focus{
            box-shadow: 0 0 3px #2261FF;
        }
    } 
}

.master_content{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: inherit;
  padding: 0 4vw;

  @at-root
  .master_content_header {
    display: flex;
    width: 100%;
    height: 60px;

    @at-root
    .master_content_header_content {
      display: flex;
      height: 100%;
      width: 100%;

      #{&}_title {
        height: 100%;
        font-size: 26px;
        display: flex;
        align-items: center;
        font-weight: bold;
        color: white;
      }

      #{&}_search_form {
        display: flex;
        -webkit-box-flex: 1;
        flex: 1;
        align-items: center;
        
        .master_content_header_content_search_form_input_group{
          margin-left: 8px;
          display: flex;
          justify-content: flex-end;
          label{
            line-height: 38px;
            margin-right: 8px;
            font-size: 16px;
            color: white;
          }
          input,
          select{
            width: 160px;
            max-width: 230px;
            font-size: 16px;
            height: 38px;
            border-color: hsl(0,0%,80%);
            border-radius: 4px;
            border-style: solid;
            border-width: 1px;
            padding: 2px 8px;
          }
        }
      }
    }
  }

  @at-root
  .master_content_body {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    margin-bottom: 2px;

    @at-root
    .master_content_body_left_content {
      display: flex;
      flex-direction: column;
      width: 375px;
      height: 100%;
      
      #{&}_header {
        display: flex;
        height: 36px;
        margin-bottom: 25px;
        label {
          margin-left: auto;
          margin-top: auto;
          margin-bottom: auto;
          margin-right: 24px;
        }
      }
      #{&}_body {
        flex: 1;
        display: flex;
        color: rgba(0, 0, 0, 0.54);
        background-color: white;
      }
    }

    @at-root
    .master_content_body_right_content_listview {
      flex: 1;
      padding-left: 0;
      list-style-type: none;
      list-style: none;
      overflow-y: scroll;
      
      // スクロールバー
      &::-webkit-scrollbar {
          width: 29px;
      }
      &::-webkit-scrollbar-track {
          background: black;
          box-shadow: inset 0 0 10px 10px black;
          border: solid 3px transparent;
      }
      &::-webkit-scrollbar-thumb {
          height: 200px;
          border-radius: 13px;
          border-left: solid 6px transparent;
          border-right: solid 6px transparent;
          border-top: solid 2px transparent;
          border-bottom: solid 2px transparent;
          box-shadow: inset 0px 0px 10px 10px #555555;
      }
      &::-webkit-scrollbar-corner {
          display: none;
      }
      
      @at-root
      .master_content_body_right_content_listview_data {
        height: 40px;
        min-height: 40px;
        max-height: 40px;
        line-height: 40px;
        text-indent: 8px;
        &li{
          border-right: 1px solid #707070;
          border-left: 1px solid #707070;
        }
        &:nth-child(even){
          background-color: #EEEEEE;
        }
        &.isSelected_listview {
          color: white;
          background-color: black;
        }
      }
      @at-root
      .master_content_body_right_content_listview_data_group{
        width: 100%;
        height: 100%;
        display: flex;
        #{&}_id {
          width: 7em;
          height: 100%;
          font-size: 12px;
        }
        #{&}_name {
          flex: 1;
          height: 100%;
          font-size: 18px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    @at-root
    .master_content_body_right_content {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    @at-root
    .master_content_body_right_content_tabs {
      height: 100%;
      margin-left: 56px;
      margin-right: 16px;
      display: flex;
      flex-direction: column;
    }
    .master_content_body_right_content_tablist {
      display: flex;
    }
    .master_content_body_right_content_tab {
      list-style: none;
      text-align: center;
      line-height: 3vh;
      margin-right: 16px;
      width: 180px;
      height: 3vh;
      border: 1px solid white;
      margin-bottom: 1vh;
      border-radius: 12px;
      background-color: unset;
      color: white;
      &[aria-selected="true"]{
        background-color: white;
        color: black;
      }
    }
    .master_content_body_right_content_tab_buttons {
      margin-left: auto;
      margin-right: 32px;
      button {
        margin-left: 16px;
        height: 30px;
      }
      padding-bottom: 8px;
    }
    .master_content_body_right_content_panel {
      display: none;
      flex-direction: column;
      overflow-y: scroll;
      overflow-x: hidden;
      &.selected {
        display: flex;
        height: fit-content;
        background-color: white;
      }

      // スクロールバー
      &::-webkit-scrollbar {
          width: 29px;
      }
      &::-webkit-scrollbar-track {
          background: black;
          box-shadow: inset 0 0 10px 10px black;
          border: solid 3px transparent;
      }
      &::-webkit-scrollbar-thumb {
          height: 200px;
          border-radius: 13px;
          border-left: solid 6px transparent;
          border-right: solid 6px transparent;
          border-top: solid 2px transparent;
          border-bottom: solid 2px transparent;
          box-shadow: inset 0px 0px 10px 10px #555555;
      }
      &::-webkit-scrollbar-corner {
          display: none;
      }
    }
    .master_content_body_right_content_form {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 200px minmax(200px,auto) 1fr;
      
      .master_content_body_right_content_form_labels{
        grid-column-start:1;
        background-color: #CCCCCC;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .master_content_body_right_content_form_inputs{
        display: flex;
        flex-wrap: wrap;
        margin-left: 16px;
        align-items: center;
        grid-column: span 2;
        color: black;
      }
      .master_content_body_right_content_form_image {
        margin-top: 8px;
        margin-left: 40px;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .master_content_body_right_content_form_small_inputs{
        display: flex;
        flex-wrap: wrap;
        margin-left: 16px;
        align-items: center;
        grid-column-start: 2;
        grid-column-end: 3;
        color: black;
      }
    }
  }
}