@import "./reset";

.site-info-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // width:100%
}

.site-info {
  padding: 15% 10%;
  // padding-top: 10%;
  // padding-bottom: 10%;
  // padding-right: 10%;
  // padding-left: 10%;
}


.info_title{
  margin: 10px 0px 50px 0px;
  font-size: 1.3em;
  // margin-top: 10px;
  // margin-bottom: 10px;
}
.info_body{
  // margin: 10% 0;
  line-height: 1.7em;
  font-size: .8em;
  letter-spacing: 1px;
  height: 400px;
  overflow: auto;
  // margin-top: 10px;
  // margin-bottom: 10px;
  p {
    margin-bottom: 15px;
  }
}
