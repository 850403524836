@import "./reset";

.App {
  // display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  background-repeat: no-repeat;
  background-color:#FFF;
  //height: 100vh;
  //width: 100vw;
  overflow-y: hidden;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border:none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112, 1);
    border-radius: 10px;
  }
}


//
// ヘッダー
//
.header {
  height: 40px;
  .header-container {
    display:flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .custom_header-container {
    display:flex;
    align-items: center;
    height: 70px;
    justify-content: space-between;
    margin-bottom: 8px;
  }
}

//
// メイン
//
// .main{
//   height: calc( 100vh - 100px);
//   width: 100vw;
// }

//
// スタッフ 
//
.staff{
  border: solid;
  border-width: 0.5px;
  // flex: 1 0 100px;
}

//
// フッター 
//
.footer{
  display: flex;
  justify-content: flex-end;
  height: 15px;
  background: #000;
}