.floor {
    // border: solid;
    // border-width: 0.5px;
    overflow: scroll;
    width: calc(100vw - 320px);
    position: relative;
    // border: solid 1px lightgray;
    height: calc( 100vh - 150px );
    margin-right: 20px;
    
    &::-webkit-scrollbar {
        width: 15px;
        height: 15px;
      }
    
      &::-webkit-scrollbar-thumb {
        border: 3px solid #000;
        background-clip: padding-box;
        background-color: rgb(112, 112, 112);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-corner {
        display: none;
    }
}

.contextMenuItem {
    font-size: .8em;
    padding: 10px 5px;
    &:hover {
        background-color: gray;
    }
}
