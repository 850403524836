.mapping{
    display: flex;
    flex-direction: column;
    height: 98%;

    .searchInfo{
            flex: 0 0 30px;
            display: flex;
            margin: 10px;

            div{
                margin-left: 20px;
            }
    }

    .mapping_set{
        margin-left:16px;
        display: flex;

        .mapping_set_left{
            padding:3px;

            .mapping_set_left_working_hours{
                display:flex;

                
                .working_hours_modal_check svg{
                    margin: auto;
                    height: 25px;
                }


                .hidden_check_for_modal{
                    display: none;
                }
            }

            .mapping_set_left_floor_map{
                position: relative;

                .mapping_set_left_area{
                    background: #ddd5;
                    width: 200px;
                    height: 200px;
                    position: absolute;
                    top: 100px;
                    left: 200px;
                    display: flex;
                    border: 1px solid #333;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    .areaName{
                        font-weight: bold;
                    }

                    svg{
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
            }


        }

        .mapping_set_right{
            // flex:0 0 200px;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

}