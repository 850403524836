.table {
	background-color: #eee;
	font-size: 0.9em;
	border-radius: 5px;
	border-collapse: collapse;
	th {
		border: solid 1px lightgray;
	}
	td {
		border: solid 1px lightgray;
	}
	thead {
		tr > th {
			font-size: 0.9em;
			padding: 8px;
			border-top: 0;
			border-left: 0;
			border-right: 0;
		}
	}
	tbody {
		tr:last-child td {
			border-bottom: 0;
		}
		tr td:first-child {
			border-left: 0;
		}
		tr td:last-child {
			border-right: 0;
		}
		tr:nth-child(odd) {
			background-color: #ddd;
		}
		tr:nth-last-child(1) {
			td:first-child {
				border-bottom-left-radius: 5px;
			}
			td:last-child {
				border-bottom-right-radius: 5px;
			}
		}
	}

}
.cell {
	padding: 8px;
	font-size: 0.9em;
	color: #111;

}