// @import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP&subset=japanese");
$staff_opacity: 0.6;
$border-left-width: 10px;
.staff {
  color: black;
  background-color: white;
  border: solid 1px gray;
  width: 151px;
  height: 70px;
  margin: 12px 16px 0px 0px;
  padding: 12px 16px;
  position: relative;
  font-size: 16px;
  border-color: black;
  // border-left: solid $border-left-width #ff0000;
  // &__male {
  //   border-color: white;
  // }
  // &__female {
  //   border-color: white;
  // }
  &__at-work {
    border-left: solid $border-left-width #004f99; 
  }
  &__leave-work {
    border-left: solid $border-left-width #be7800;
  }
  &__break-time {
    border-left: solid $border-left-width #006e46;
  }
  &__not-present{
    border-left: solid $border-left-width #990028;
  }
  &__off-duty {
    border-left: solid $border-left-width #555555;
  }
}

.staffName {
  // font-family: ‘Noto Sans JP’, sans-serif;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color:black;
  // font-weight: bold;
}

.shift {
  color: black;
  font-size: 15px;
  // font-family: ‘Noto Sans JP’, sans-serif;
  padding-top: 8px;
}

.wbgtstatus-warning {
  background-color: #fffd54;
}
.wbgtstatus-hardwarning {
  background-color: #fffd54;
}
.wbgtstatus-danger {
  background-color: #fffd54;
}
// .alert {
//     position: absolute;
//     top: 5px;
//     right: 5px;
//     color: #eb3424;
//     background-color: #fffd54;
//     display: none;
//     &__show {
//       display: inline;
//     }
// }